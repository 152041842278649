import React from "react";
import { Formik, Form } from 'formik';
import { SubmitFormButtonStyled } from "../SubmitForm/SubmitForm.styles";

const FormComponent = ({
  className,
  submitText,
  handleSubmit,
  initialValues,
  validaton,
  children,
}) => (
  <div className={className}>
    <Formik
      onSubmit={(values, actions) => {
        handleSubmit(values, actions);
      }}
      initialValues={initialValues}
      validationSchema={validaton}
    >
      {(formikProps) => {
        const { isValid, dirty, errors } = formikProps;
        return (
          <Form>
            {children(formikProps)}
            {errors.name && <div>{errors.name}</div>}
            <SubmitFormButtonStyled type="submit" disabled={!(isValid && dirty)} tabIndex={0}>
              {submitText}
            </SubmitFormButtonStyled>
          </Form>
        );
      }}
    </Formik>
  </div>
);

export default FormComponent