import React from "react";
import FormContainerStyles from "./FormContainer.styles";
import { Grid } from "@mui/material";
import SubmitForm from "../SubmitForm/SubmitForm";

const FormContainer = () => (
    <FormContainerStyles>
        <Grid item className="form-banner--text">
            <p className="typography typography-heading--text typography-color--black">The path to financial security starts with just one click</p>
        </Grid>
        <Grid container justifyContent="center">
            <SubmitForm/>
        </Grid>
    </FormContainerStyles>
)

export default FormContainer;