import MainLayout from "./Components/MainLayout";
import './App.css';
import FormContainer from "./Components/FormContainer";
import { Grid } from "@mui/material";

function App() {
  return (
    <MainLayout>
      <FormContainer/>
      <Grid container justifyContent="center" className="about-section--container">
        <Grid container justifyContent="center" className="about-section">
          <h2 className="typography typography-heading--secondary typography-color--heading">Easy Life Whole Life insurance provides peace of mind for you and financial security for your loved ones.</h2>
          <p className="typography typography-color--darker">A conversation about Life Insurance is something many people avoid. Making decisions about final expenses, debt coverage and leaving a legacy can be overwhelming, but it doesn't have to be that way.</p>
          <p className="typography typography-color--darker">We are delighted to offer this unique opportunity to clients of Joseph M. Wiedemann & Sons to help you navigate these crucial decisions with ease.</p>
        </Grid>
      </Grid>
    </MainLayout>
  );
}

export default App;
