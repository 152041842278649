import React from "react";
import SubmitFormStyled, { SubmitFormInnerStyled } from "./SubmitForm.styles";
import FormComponent from "../FormComponent";
import submitFormValidationSchema from "./validationSchemas";
import Input from "../Input/Input";
import emailjs from 'emailjs-com';

const SubmitForm = () => {

    const onSubmit = (values) => {        
        
        emailjs.send('service_2g302i1', 'template_vaxi3ye', values, 'c20nxuYnRKZB2mXGO')
            .then((result) => {    
                window.location.assign("http://www.emcnlife.com/wiedemann/Default.aspx?w=d"); 
            }, (error) => {
                console.log(error.text);
            });
    
    }

    return (
    <SubmitFormStyled>
        <div className="submit-form--container">
            <p className="typography typography-heading--tretiary">Get your quote now!</p>

            <FormComponent
                className="submit-form"
                submitText="Get Quote"
                handleSubmit={onSubmit}
                validaton={submitFormValidationSchema}
                initialValues={{
                    from_name: "",
                    from_phone: ""
                }}
            >
                {({ errors, touched }) => (
                    <SubmitFormInnerStyled>
                        <Input
                            name="from_name"
                            label="Full Name"
                            placeholder="First and last name"
                            touched={touched}
                            errors={errors}
                        />
                        <Input
                            name="from_phone"
                            label="Phone No."
                            placeholder="19876543210"
                            touched={touched}
                            errors={errors}
                        />           
                    </SubmitFormInnerStyled>
                )}
            </FormComponent>
        </div>
    </SubmitFormStyled>
)

}

export default SubmitForm;
