import styled from "styled-components";

export const HeaderStyled = styled('div')`
position: fixed;
margin: 0;
top: 0;
left: 0;
width: 100%;
height: 132px;
display: flex;
align-items: center;
padding: 0 200px;
background: #FFFFFF;
border-bottom: 1px solid #EDEDED;

@media screen and (max-width: 780px) {
    height: 94px;
    padding: 0 20px;
}
`;

export default HeaderStyled;