import React from "react";
import FooterStyled from "./Footer.styles";
import { Grid } from "@mui/material";
import LogoFooter from "../../Assets/emc-logo.svg";
import { ReactComponent as PhoneIcon } from "../../Assets/phone.svg";
import { ReactComponent as MailIcon } from "../../Assets/mail.svg";
import { ReactComponent as SocialFacebook } from "../../Assets/facebook.svg";
import { ReactComponent as SocialTwitter } from "../../Assets/twitter.svg";
import { ReactComponent as SocialLinkedin } from "../../Assets/linkedin.svg";
import { ReactComponent as SocialInsta } from "../../Assets/instagram.svg";

const Footer = () => (
    <FooterStyled>
        <Grid container className="footer--content-container" direction="column" justifyContent="center">
            <Grid container item className="footer-contacts--container">
                <Grid item className="footer-contacts--item">
                    <PhoneIcon/>
                    <p className="typography typography--text-footer typography-color--white">708-630-9121</p>
                </Grid>
                <Grid item className="footer-contacts--item">
                    <MailIcon/>
                    <a className="typography typography--text-footer typography-color--white" href="mailto:help@emcnl.com">help@emcnl.com</a>
                </Grid>
            </Grid>

            <Grid item className="footer-logo--container">
                <img src={LogoFooter} alt="emc logo"/>
            </Grid>

            <Grid item className="footer-social--container">
                <Grid item className="footer-social--item">
                    <a href="#">
                        <SocialFacebook/>
                    </a>
                </Grid>
                <Grid item className="footer-social--item">
                    <a href="#">
                        <SocialTwitter/>
                    </a>
                </Grid>
                <Grid item className="footer-social--item">
                    <a href="#">
                        <SocialLinkedin/>
                    </a>
                </Grid>
                <Grid item className="footer-social--item">
                    <a href="#">
                        <SocialInsta/>
                    </a>
                </Grid>
            </Grid>
        </Grid>

        <Grid container className="copyright" justifyContent="center">
            <p className="typography typography--text-footer typography-color--secondary">
                <span>EMC National Life Company Des Moines, IA</span> - © Copyright 2023. All Rights Reserved.
            </p>
        </Grid>
    </FooterStyled>
)

export default Footer