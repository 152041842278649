import React from "react";
import { CSSTransition } from 'react-transition-group';
import { Field, ErrorMessage } from 'formik';
import { useRef } from 'react';
import InputStyles from './Input.styles';

const Input = ({
  className,
  name,
  label,
  type,
  as,
  placeholder,
  disabled,
  errors,
  touched,
}) => {
  const transitionRef = useRef();

  return (
    <InputStyles className={className}>
      <div className="input-field--wrapper">
        {label && (
          <label htmlFor={name} className="input--label">
            {label}
          </label>
        )}
        <div className="input--wrapper">
            <Field
              type={type}
              as={as}
              name={name}
              placeholder={placeholder}
              disabled={disabled}
              className={`input 
                  ${errors[name] && touched[name] ? 'state-danger' : ''}`}
            />
        </div>
        {name && (
          <CSSTransition
            in={errors[name] && touched[name]}
            timeout={500}
            classNames="error-block-animation"
            unmountOnExit
            nodeRef={transitionRef.current}
          >
            <div className="input-error--wrapper" ref={transitionRef}>
              <ErrorMessage
                name={name}
                render={(msg) => (
                  <div className="input-error--container">
                    <div className="input-error--message">{msg}</div>
                  </div>
                )}
              />
            </div>
          </CSSTransition>
        )}
      </div>
    </InputStyles>
  );
};

export default Input;
