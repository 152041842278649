import React from "react";
import MainLayoutStyled from "./MainLayout.styles";
import Header from "../header/Header";
import Footer from "../Footer/Footer";

const MainLayout = (props) => (
    <MainLayoutStyled>
        <Header/>
        <div className="content-container">{props.children}</div>
        <Footer/>
    </MainLayoutStyled>
)

export default MainLayout