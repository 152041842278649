import styled from "styled-components";

export const MainLayoutStyled = styled('div')`
width: 100%;

.content-container {
    padding-top:104px;
    

    @media screen and (max-width: 780px) {
        padding-top:95px;
    }
}
`

export default MainLayoutStyled;