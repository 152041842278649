import styled from "styled-components";

export const InputStyles = styled('div')`
width: 100%;

.input-field--wrapper {
    width: 100%;

    .input--label {
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
    }

    .input--wrapper {
        width: 100%;
        padding-top: 5px;

        .input {
            width: 100%;
            background-color: #FFFFFF;
            border: 1px solid #D7DEDD;
            border-radius: 6px;
            height: 46px;
            font-family: Lato;
            font-size: 18px;
            font-weight: 400;
            padding: 0 10px;
            color: #333333;
            
            &::placeholder {
              color: #919191;
            }

            &.state-danger {
              border: 1px solid red;
            }
        }
    }
}

.input-error--wrapper {
    width: 100%;

    &.error-block-animation-enter {
      opacity: 0;
    }

    &.error-block-animation-enter-active {
      opacity: 1;
      transition: opacity 500ms;
    }

    &.error-block-animation-exit {
      opacity: 1;
    }

    &.error-block-animation-exit-active {
      opacity: 0;
      transition: opacity 500ms;
    }

    .input-error--container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 5px;

      .input-error--message {
        color: red;
      }
    }
  }
`

export default InputStyles