import styled from "styled-components";

export const SubmitFormStyled = styled('div')`
display: flex;
border: 1px solid #D7DEDD;
box-shadow: 0px 4px 3px 0px #00000005;
box-shadow: 0px 22px 22px 0px #00000054;
border-radius: 10px;
padding: 46px;
background: #FFFFFF;
width: 370px;

@media screen and (max-width: 780px) {
    width: 250px;
} 

.submit-form--container {
    width: 100%;
    
    .submit-form {
        width: 100%;

        form {
            width: 100%;
        }
    }
}
`


export const SubmitFormInnerStyled = styled('div')`
display: flex;
flex-direction: column;
padding-right: 22px;
padding-top: 18px;
padding-bottom: 32px;
gap: 18px;
`
export const SubmitFormButtonStyled = styled('button')`
display: flex;
align-items: center;
justify-content: center;
background: #293A86;
color: #FFFFFF;
width: 100%;
height: 52px;
font-family: Lato;
font-size: 22px;
font-weight: 500;
text-align: center;
border: none;
border-radius: 6px;
cursor: pointer;
`

export default SubmitFormStyled;