import styled from "styled-components";
import background from "../../Assets/background.jpg"

const FormContainerStyles = styled('div')`
display: flex;
height: 342px;
padding: 158px 32px;
background: 100% bottom/ 145% no-repeat url('${background}');
justify-content: center;
flex-direction: column;
gap: 30px;    

.form-banner--text {
    text-align: center;
} 

@media screen and (max-width: 780px) {
    align-items: center;
    padding: 130px 0;
    padding-bottom: 170px;
    background: center / cover no-repeat url('${background}');
    
    .typography {
        margin: 0px 15px;
    }
} 

@media screen and (max-width: 334px) {
    .typography-heading--text {
      margin-top: 30px;
    }
} 
`
export default FormContainerStyles;