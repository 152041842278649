import styled from "styled-components";

export const FooterStyled = styled('div')`
background: #1C2023;
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
padding: 38px 0;
gap: 30px;

.footer--content-container {
    max-width: 290px;

    .footer-contacts--container {
        gap: 24px;

        .footer-contacts--item {
            display: flex;
            align-items: center;

            svg {
                margin-right: 8px;
            }
        }
    }

    .footer-logo--container {
        display: flex;
        justify-content: center;
    }

    .footer-social--container {
        display: flex;
        align-items: center;
        gap: 24px;
        justify-content: center;

        .footer-social--item {

        }
    }
    
}

.copyright {
    justify-content: center;
    text-align: center;
}
`;

export default FooterStyled;